<template>
  <div
    class="w-full print:hidden bg-[#AA5A9F] text-white pb-[96px] md:pb-0 relative"
  >
    <div
      class="max-w-screen-xl mx-auto z-100 grid grid-cols-1 lg:grid-cols-3 px-md xl:px-0 pb-[var(--sticky-btn-height)] lg:pb-0 touch:md:pb-0"
    >
      <div
        class="flex items-center h-full pt-md lg:pt-0 justify-self-center lg:justify-self-start"
      >
        <BasicLink
          :label="t('repairshopForm.backLabel')"
          class="font-pero text-[18px]"
          target="_self"
          icon="fas fa-chevron-left"
          hash="#repairshop-basic"
          :link="parentPage"
          :icon-position="EPosition.LEFT"
        />
      </div>
      <div
        class="flex flex-row items-center justify-center justify-self-center md:justify-start"
      >
        <NuxtLink class="flex" to="/">
          <img
            src="~/assets/images/minilu/footer-minilu.svg"
            title="van der ven logo"
            alt="van der ven logo"
            class="h-[96px] absolute bottom-[var(--sticky-btn-height)] md:bottom-0"
          />
          <div class="ml-[5px] md:ml-[130px] font-pero text-[22px] py-[11.5px]">
            {{ t('footer.smallPrices') }}
          </div>
        </NuxtLink>
      </div>
      <div
        class="flex items-center h-full justify-self-center lg:justify-self-end pb-md lg:pb-0"
      >
        <BasicLink
          class="pr-md font-pero text-[18px]"
          link="/agb"
          :label="t('repairshopForm.footer.agb')"
          target="_blank"
        />
        <BasicLink
          class="pr-md font-pero text-[18px]"
          link="/datenschutz"
          :label="t('repairshopForm.footer.privacy')"
          target="_blank"
        />
        <BasicLink
          class="font-pero text-[18px]"
          link="/impressum"
          :label="t('repairshopForm.footer.impressum')"
          target="_blank"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { EPosition } from '~/@types/basic-button';
import BasicLink from '@/components/components/core/basicLink/basic-link.vue';

const { t } = useTrans();
const parentPage = computed(() => {
  const sourceUrl = useRouter().currentRoute.value.query['sourceUrl'] as string;
  if (sourceUrl) {
    return sourceUrl;
  }
  return '/';
});
</script>
